.grid {
    &-fullpage {
        justify-content: center;
        align-items: center;
        height: 99vh;
        width: 100%;
    }

    &-block {
        display: block;
    }
}

.pi-icons-big {
    font-size: 2rem !important;
}

.topbar-name {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 19px;
}

.card {
    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        // justify-content: space-between;

        & button {
            margin-left: 10px;
        }

        & h5 {
            margin-bottom: 7px;
        }
    }
}

.p-button {
    &-blank {
        border: 0;
        background: none;
        color: #000;
        padding: 4px !important;
        margin: 0;
        width: auto !important;

        & .p-button-icon{
            font-size: 17px;
        }
    }

}

.card > .cat__tree {
    padding-left: 0;
}

.cat__tree {
    &-item {
        list-style: none;
    }

    &-title {
        padding: 7px 5px 7px 10px;
        background: #f5f5f5;
        border-radius: 4px;
        margin-bottom: 3px;
        position: relative;
        cursor: pointer;
        transition: .3s all;
    }

    &-title:hover {
        background: #dddef7;
    }

    &-title.has-childs {
        padding-left: 30px;
    }

    &-title.has-childs:before {
        content: "\e902";
        font-family: "primeicons";
        position: absolute;
        margin-top: 1px;
        transform: rotate(270deg);
        transition: .3s all;
        left: 10px;
    }

    &-title.has-childs.active:before {
        transform: rotate(0);
    }
}

td.text-center.cell-button {
    max-width: 50px;
    width: 50px;
}

.td-click {
    cursor: pointer;
    transition: .3s all;

    &:hover {
        color: #000;
        background: #f8f9fa;
    }
}

.breadcrumb-chevron:before {
    color: #495057;
    padding: 0 3px;
}

.nopadding {
    padding: 0 !important;
}

.post__save-btn {
    display: flex;
    justify-content: center;
    
    & button {
        margin-top: 20px;
        max-width: 300px;
    }
}

img.post__icon {
    max-width: 70px;
    max-height: 70px;
    height: 70px;
    width: 70px;
    border: 1px solid #e5e5ef;
    border-radius: 5px;
    padding: 1px;
    object-fit: cover;
    cursor: no-drop;
}

.editor {
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding: 5px 5px 15px;
    margin-bottom: 20px;
}

.uploadDialog .p-dialog .p-dialog-footer {
    text-align: center;
}

.td-image {
    max-height: 20px;
    margin-right: 10px;
    margin-bottom: -5px;
}

.card__header--wbuttons {
    justify-content: space-between;
}

button.header__csv-btn {
    background: #22814d;
    color: #fff;
    border: 0;
    font-size: 15px;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.customfieds__textarea {
    max-width: 800px; 
    width: 100%;
}

.p-datatable {
    position: relative;
    overflow: auto;
}